/* global gettext, ngettext, interpolate */
import "blissfuljs"
const $ = window.Bliss

import { render } from "preact"
import { useEffect, useRef, useState } from "preact/hooks"
// import _throttle from "lodash.throttle"
import { useStoredState } from "./useStoredState.js"

const LS_KEY = "votinglist"
const VERSION = 1

export const renderVoting = (el) => {
  const script = el.querySelector("script")
  const data = JSON.parse(script.textContent)
  render(
    <VotingList categories={data} backLink={el.dataset.votingBacklink} />,
    el,
  )
}

function entriesOfCategory(votingList, category) {
  return votingList[category] || []
}

function toggleOnVotingList(votingList, data) {
  let entries = entriesOfCategory(votingList, data.category.id)
  if (entries.find((entry) => entry.id === data.id)) {
    entries = entries.filter((entry) => entry.id !== data.id)
  } else {
    entries = [...entries, data]
  }
  return { ...votingList, [data.category.id]: entries }
}

function removeFromVotingList(votingList, category, id) {
  return {
    ...votingList,
    [category]: entriesOfCategory(votingList, category).filter(
      (entry) => entry.id !== id,
    ),
  }
}

function VotingList({ categories, backLink }) {
  const [hidden, setHidden] = useState(true) // Hidden by default
  const toggle = useRef()

  document.body.classList.toggle("votinglist--active", !hidden)

  const [votingList, setVotingList] = useStoredState({
    key: LS_KEY,
    version: VERSION,
  })

  useEffect(() => {
    const form = document.querySelector("[data-voting-form]")
    const formContainer = document.querySelector("[data-voting-form-container]")
    form.style.display = "block"

    formContainer.appendChild(form)

    $.delegate(
      document.querySelector("main"),
      "click",
      "[data-voting-entry]",
      (e) => {
        e.preventDefault()
        const target = e.target
        setHidden(false)
        if (!target.classList.contains("deactivate")) {
          const data = {
            id: target.dataset.votingId,
            url: target.dataset.votingUrl,
            title: target.dataset.votingTitle,
            votes_per_category: target.dataset.votingCategoryVotes,
            category: {
              id: target.dataset.votingCategoryId,
              title: target.dataset.votingCategoryTitle,
              color: target.dataset.votingCategoryColor,
              is_too_dark: target.dataset.votingDark,
            },
          }

          // console.log(data)

          // data.id, data.category.id, data.
          // const script = target.querySelector("script")
          // const data = JSON.parse(script.textContent)
          if (data) {
            setVotingList(toggleOnVotingList(votingList, data))
            // TODO add highlights for better UX
            // toggle.current.classList.add("highlight")
          }
        }
      },
    )
    return () => {
      document.querySelector("main")._.unbind("click")
    }
  })

  useEffect(() => {
    const identifiers = categories.flatMap((e) => {
      const votingLists = entriesOfCategory(votingList, e.id)

      return [...votingLists.flatMap((entry) => `${e.id}:${entry.id}`)]
    })

    const categoryIdentifiers = categories.flatMap((e) => {
      const votingLists = entriesOfCategory(votingList, e.id)

      return [...votingLists.flatMap(() => `${e.id}`)]
    })

    // const identifiers = identifiersArray.join("")

    const activeVotes = categories.flatMap((e) => {
      const votingLists = entriesOfCategory(votingList, e.id)
      return [...votingLists.flatMap((entry) => `${entry.id}`)]
    })

    const activeVotesList = activeVotes.join(",")

    const voteInput = document.getElementById("id_votes")
    voteInput.value = activeVotesList
    document.querySelectorAll("[data-voting-entry]").forEach((el) => {
      if (!el.dataset.votingEntry) return
      el.parentElement.classList.toggle(
        "active",
        identifiers.includes(el.dataset.votingEntry),
      )
      if (
        categoryIdentifiers.filter(
          (x) => x === `${el.dataset.votingCategoryId}`,
        ).length >= categories[0].votes_per_category &&
        !el.parentElement.classList.contains("active")
      ) {
        el.classList.add("deactivate")
      } else {
        el.classList.remove("deactivate")
      }
    })
  })

  // TODO: use relative votes per category, doesn't fit well with actual voting concept right now
  const openVotes =
    3 -
    Object.values(votingList).reduce((acc, votes) => {
      return votes[0] ? acc + votes.length : acc
    }, 0)

  const votes_per_category =
    document.querySelector("[data-voting-form]").dataset.votesPerCategory

  return (
    <div class={"votinglist"}>
      <button
        class="votinglist-toggle"
        ref={toggle}
        onClick={() => setHidden(!hidden)}
      >
        <svg class="control icon icon--small icon--arrow-left-black">
          <use xlinkHref="#arrow-left-black" />
        </svg>
      </button>
      <div class="votinglist-panel">
        <h3 class="text--margin">{gettext("My voting")}</h3>
        <p class="text--margin">{gettext("voting box intro")}</p>
        <div class="votinglist-panel__info-box">
          <svg class="votinglist-panel__info-icon">
            <use xlinkHref="#icon-info" />
          </svg>

          <p class="votinglist-panel__info-text">
            {interpolate(
              ngettext(
                "%s vote must be cast.",
                "%s votes must be cast.",
                votes_per_category,
              ),
              [votes_per_category],
            )}
            {openVotes > 0 ? (
              <a class="no-decoration" href={backLink}>
                <svg class="control icon icon--small icon--arrow-right-black">
                  <use xlinkHref="#arrow-right-black" />
                </svg>
                {gettext("See all ideas")}
              </a>
            ) : (
              ""
            )}
          </p>
        </div>
        <div class={"watchlist-body-wrapper"}>
          {categories.map((category) => (
            <CardList
              key={category.id}
              entries={entriesOfCategory(votingList, category.id)}
              category={category}
              removeItem={(id) =>
                setVotingList(removeFromVotingList(votingList, category.id, id))
              }
            />
          ))}
        </div>
        <div data-voting-form-container />
      </div>
    </div>
  )
}

function CardList({ entries, category, removeItem }) {
  if (!entries.length) return null

  return (
    <>
      <div class="votinglist-list">
        <div class={"levers levers--padding"}>
          <span
            class={`lever ${
              category.is_too_dark ? "text--white" : ""
            } no-decoration`}
            style={`background-color: ${category.color}`}
          >
            {category.title}
          </span>
        </div>
        {entries.map((entry, idx) => (
          <Card
            key={entry.id}
            number={idx + 1}
            removeItem={removeItem}
            {...entry}
          />
        ))}

        {entries.length < 3 ? (
          <div class="votinglist-entry votinglist-entry--add">
            {/* FIXME: is "/spotlight/wall-of-fame/" the right hardcoded URL? */}
            <a class="no-decoration" href={category.url} title={category.title}>
              <h4>
                {gettext("Vote:")} {entries.length + 1}
              </h4>
              <svg
                width="62"
                height="67"
                viewBox="0 0 62 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="19.9978"
                  y1="34.0022"
                  x2="42.2222"
                  y2="34.0022"
                  stroke="#F5C387"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <circle
                  cx="31"
                  cy="33.8291"
                  r="21.4695"
                  transform="rotate(45 31 33.8291)"
                  fill="#0A0A0F"
                  stroke="#0A0A0F"
                  stroke-width="0.657139"
                />
                <path
                  d="M30.5 45.002V22.7776"
                  stroke="#F5C387"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <line
                  x1="19.9979"
                  y1="34.0022"
                  x2="42.2222"
                  y2="34.0022"
                  stroke="#F5C387"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </a>
          </div>
        ) : null}
      </div>
    </>
  )
}

function Card({ title, id, url, removeItem, number }) {
  const handleRemove = (e) => {
    e.preventDefault()
    removeItem(id)
  }

  return (
    <div class={"votinglist-entry"}>
      <a class="no-decoration" href={url} title={title}>
        <h4>
          {gettext("Vote:")} {number}
        </h4>
        <span>{title}</span>
      </a>
      <a class="votinglist-entry__icon" href="#" onClick={handleRemove}>
        <svg class="control icon icon--small icon--trash">
          <use xlinkHref="#icon-trash" />
        </svg>
      </a>
    </div>
  )
}
