import "./reset.css"
import "./grid.css"

import "./google-fonts-1693813299521.css"

/* Components */
// import "./announcements.css"
import "./accordion.css"
import "./buttons.css"
import "./content.css"
import "./footer.css"
import "./forms.css"
import "./functional.css"
import "./header.css"
import "./icons.css"
import "./messages.css"
import "./pagination.css"
import "./tables.css"
import "./teasers.css"
import "./typography.css"
import "./vcard.css"
import "./votinglist.css"
import "./f3cc.css"

/* Variables, base styles */
import "./main.css"

import BigPicture from "bigpicture"
import { Accordion } from "./accordion.js"
import { initBacklinks } from "./backlinks"
import { initForms } from "./forms.js"
import { initMenuToggle } from "./navigation.js"
import { initSolutionList } from "./solutionList"
import { initSolutionNavigation } from "./solutionNavigation"
import { onReady } from "./utils.js"
import initVotingForm from "./votingForm.js"
import { renderVoting } from "./votingList.js"

onReady(() => {
  initVotingForm()

  const accordionWrapper = document.querySelectorAll("[data-accordion]")
  accordionWrapper.forEach((el) => new Accordion("[data-accordion-item]", el))
  const votingEl = document.querySelector("[data-voting]")
  if (votingEl) {
    renderVoting(votingEl)
  }

  const scrollAnchors = document.querySelectorAll("[data-scroll-anchor]")

  scrollAnchors.forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault()
      const target = document.getElementById(`${e.target.dataset.scrollAnchor}`)
      window.scroll({ top: target.offsetTop + 666, behavior: "smooth" })
    })
  })

  const videoPopup = document.querySelectorAll("[data-video-popup]")

  function openVideo(e, id) {
    BigPicture({
      el: e.target,
      vimeoSrc: id,
      autoplay: true,
    })
  }

  if (videoPopup) {
    videoPopup.forEach((video) => {
      const videoID = video.dataset.vimeosrc.replace("https://vimeo.com/", "")
      video.addEventListener("click", (e) => {
        e.preventDefault()
        openVideo(e, videoID)
      })
    })
  }

  function openImage(e, id) {
    BigPicture({
      el: e.target,
      imgSrc: id,
      noLoader: true,
    })
  }

  const imagePopup = document.querySelectorAll("[data-image-popup]")
  if (imagePopup) {
    imagePopup.forEach((image) => {
      const imageId = image.dataset.imgsrc
      image.addEventListener("click", (e) => {
        e.preventDefault()
        openImage(e, imageId)
      })
    })
  }
})

onReady(initMenuToggle)
onReady(initForms)
onReady(initBacklinks)
onReady(initSolutionList)
onReady(initSolutionNavigation)
