export const initBacklinks = () => {
  const anchors = document.querySelectorAll(".backlink-fn")
  if (anchors) {
    anchors.forEach((anchor) => {
      const href = anchor.getAttribute("href")
      const referrer = document.referrer
      if (referrer && referrer.search(href) >= 0) {
        anchor.addEventListener("click", (e) => {
          e.preventDefault()
          history.back()
        })
      }
    })
  }
}
