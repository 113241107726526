export function initMenuToggle() {
  const body = document.body
  body.addEventListener("click", (e) => {
    const target = e.target?.closest("[data-menu-toggle]")
    if (target) {
      e.preventDefault()
      target.toggleAttribute("aria-expanded")
      body.classList.toggle("menu-is-open")
      if (body.classList.contains("menu-is-open")) {
        window.addEventListener("resize", () => {
          target.setAttribute("aria-expanded", false)
          body.classList.remove("menu-is-open")
        })
      }
    }
  })
}
