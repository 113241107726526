export function onReady(fn) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1)
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

export const getCookie = (name) => {
  const cookies = document.cookie
    .split("; ")
    .reduce((cookiesObject, cookieString) => {
      const cookie = cookieString.split("=")

      cookiesObject[cookie[0]] = cookie[1]

      return cookiesObject
    }, {})

  return cookies[name]
}
