/* global gettext */
import { render } from "preact"
import { useEffect, useState } from "preact/hooks"
import { sessionStorage } from "./storage"

import "./solutionList.css"

export const initSolutionList = () => {
  const lst = document.querySelector("#solution-list")
  const lstData = document.querySelector("#solution-data")
  if (lst && lstData) {
    render(
      <SolutionList
        data={JSON.parse(lstData.textContent)}
        votingPhase={JSON.parse(lst.dataset.votingPhase)}
        votingSort={JSON.parse(lst.dataset.votingSort)}
        noButton={lst.dataset.noButton}
      />,
      lst,
    )
  }
}

const getOrdering = (data, type) => {
  let ordering = {}

  try {
    ordering = JSON.parse(sessionStorage.getItem("spotlight-ordering"))
  } catch (_e) {
    /* do nothing */
  }

  if (!ordering || ordering.type !== type) {
    let items = []
    if (type === "abc-") {
      items = data.sort((a, b) => a.name.localeCompare(b.name))
    } else if (type === "abc+") {
      items = data.sort((a, b) => b.name.localeCompare(a.name))
    } else if (type === "votes-") {
      items = data.sort((a, b) => a.votes - b.votes)
    } else if (type === "votes+") {
      items = data.sort((a, b) => b.votes - a.votes)
    } else {
      // pseudo-random shuffle
      items = data
        .map((n) => {
          return [Math.random(), n]
        })
        .sort()
        .map((n) => {
          return n[1]
        })
    }

    const checkResetType = type === "reset" ? "random" : type

    ordering = {
      type: checkResetType,
      items: items.map((item) => {
        return { id: item.id, url: item.url }
      }),
    }
    sessionStorage.setItem("spotlight-ordering", JSON.stringify(ordering))
  }
  return ordering
}

const getItems = (data, orderingType, search) => {
  const dataOrdering = getOrdering(data, orderingType)
  return data
    .filter((e) => {
      return search !== "" ? e.name.includes(search.toUpperCase()) : true
    })
    .sort(
      (a, b) =>
        dataOrdering.items.findIndex((e) => e.id === a.id) -
        dataOrdering.items.findIndex((e) => e.id === b.id),
    )
}

const SolutionList = ({ data, votingPhase, votingSort, noButton }) => {
  const [orderingType, setOrderingType] = useState(
    JSON.parse(sessionStorage.getItem("spotlight-ordering"))?.type || "random",
  )
  const [search, setSearch] = useState("")
  const [slice, setSlice] = useState(getItems(data, orderingType, search))

  useEffect(() => {
    setSlice(getItems(data, orderingType, search))
    if (orderingType === "reset") setOrderingType("random")
    if (
      !votingSort &&
      (orderingType === "votes+" || orderingType === "votes-")
    ) {
      setOrderingType("random")
    }
  }, [data, orderingType, setOrderingType, search, votingSort])

  return (
    <>
      <div class="solution-list--controls">
        <div class="search-wrap">
          <input
            type="text"
            value={search}
            placeholder={gettext("search")}
            onInput={(e) => setSearch(e.target.value)}
          />
          <i class="icon icon--list-control search" />
        </div>
        <ListControlRadioButton
          orderingType={orderingType}
          value="abc-"
          setOrderingType={setOrderingType}
        >
          <span>{gettext("A - Z")}</span>
          <i class="icon icon--list-control arrow--down" />
        </ListControlRadioButton>
        <ListControlRadioButton
          orderingType={orderingType}
          value="abc+"
          setOrderingType={setOrderingType}
        >
          <span>{gettext("Z - A")}</span>
          <i class="icon icon--list-control arrow--up" />
        </ListControlRadioButton>
        {votingSort && (
          <>
            <ListControlRadioButton
              orderingType={orderingType}
              value="votes-"
              setOrderingType={setOrderingType}
            >
              <span>{gettext("votes")}</span>
              <i class="icon icon--list-control arrow--down" />
            </ListControlRadioButton>
            <ListControlRadioButton
              orderingType={orderingType}
              value="votes+"
              setOrderingType={setOrderingType}
            >
              <span>{gettext("votes")}</span>
              <i class="icon icon--list-control arrow--up" />
            </ListControlRadioButton>
          </>
        )}

        <div class="button-wrap">
          <button
            type="button"
            onClick={() => {
              setOrderingType("reset")
            }}
          >
            <span>{gettext("random")}</span>
            <i class="icon icon--list-control reset" />
          </button>
        </div>
      </div>

      <div class="grid grid--gx grid--gy grid--cards space--md">
        {slice.map((solution) => (
          <SolutionCard
            key={solution.id}
            data={solution}
            votingPhase={votingPhase}
            noButton={noButton}
          />
        ))}
      </div>
    </>
  )
}

const ListControlRadioButton = ({
  orderingType,
  value,
  setOrderingType,
  children,
}) => {
  return (
    <div class="radio-wrap">
      <input
        id={`ordering-${value}`}
        name="ordering"
        type="radio"
        class="show-for-sr"
        value={value}
        checked={orderingType === value}
        onClick={(e) => {
          setOrderingType(e.target.value)
        }}
      />
      <label for={`ordering-${value}`}>{children}</label>
    </div>
  )
}

export const SolutionCard = ({ data, votingPhase, noButton }) => {
  return (
    <div class="cell--small">
      <div
        id="solution-card"
        class="card no-decoration"
        data-voting-entry-title={data.title}
        data-voting-entry-category={data.category_title}
        data-solution-id={data.id}
      >
        <a class="card-image__container" href={data.url}>
          <img class="card-image" src={data.image} alt={data.title} />
        </a>
        <div class="card-body card-body--solution">
          <div class="card-body__main">
            <div class="card-body__header">
              <div class="levers">
                <span
                  class={`lever ${data.is_too_dark ? "text--white" : ""}`}
                  style={`background-color:${data.category_color}`}
                >
                  {data.category_title}
                </span>
              </div>
              {noButton ? (
                // <span class="solution-votes">
                //   <span class="text--medium">{data.votes}</span>
                // </span>
                ""
              ) : (
                <span
                  className={`solution-votes ${
                    votingPhase ? "solution-votes--voting" : ""
                  }`}
                  data-voting-id={data.id}
                  data-voting-url={data.url}
                  data-voting-title={data.title}
                  data-voting-category-id={data.category_id}
                  data-voting-category-title={data.category_title}
                  data-voting-category-color={data.category_color}
                  data-voting-dark={data.is_too_dark}
                  data-voting-category-votes={data.votes_per_category}
                  data-voting-entry={`${data.category_id}:${data.id}`}
                >
                  {data.show_votes_count && (
                    <span class="text--medium">{data.votes}</span>
                  )}
                  <svg class="control icon icon--small icon--right">
                    <use xlinkHref="#icon-vote" />
                  </svg>
                </span>
              )}
            </div>
            <a class="no-decoration" href={data.url}>
              <h3 class="text--margin">{data.title}</h3>
            </a>
          </div>
          <div class="card-footer">
            {/* TODO: Fix voting_script solution */}
            {!noButton && votingPhase ? (
              <a
                href="#"
                data-voting-id={data.id}
                data-voting-url={data.url}
                data-voting-title={data.title}
                data-voting-category-id={data.category_id}
                data-voting-category-title={data.category_title}
                data-voting-category-color={data.category_color}
                data-voting-dark={data.is_too_dark}
                data-voting-category-votes={data.votes_per_category}
                data-voting-entry={`${data.category_id}:${data.id}`}
                class="button button--large button--primary"
              >
                {gettext("Vote")}
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
