import { useEffect, useState } from "preact/hooks"
import { localStorage } from "./storage.js"

const chuckOld = (data, version) => {
  /* versioning of watch list data structure */
  let new_ = data || {}
  if (new_._v !== version) {
    new_ = {}
  }
  new_._v = version
  return new_
}

export function useStoredState({ key, version }) {
  const [state, setStateWithoutLS] = useState(() => {
    let data
    try {
      data = JSON.parse(localStorage.getItem(key))
    } catch (_e) {
      /* nothing */
    }
    return chuckOld(data, version)
  })

  const setState = (data) => {
    setStateWithoutLS(data)
    localStorage.setItem(key, JSON.stringify(data))
  }

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (e.key === key) {
        setStateWithoutLS(chuckOld(JSON.parse(e.newValue), version))
      }
    })
  }, [key, version])

  return [state, setState]
}
